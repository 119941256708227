<script lang="ts">
  import { useTranslations } from "@/i18n";
  import type { LangType } from "@/types/i18n";

  export let lang: LangType;
  export let showActionButtons: boolean = false;
  export let isHeader: boolean = false;

  let keyword: string = "";

  const { t, localizeUrl } = useTranslations(lang);
  const isEnglish = lang === "en";

  const handleSubmit = () => {
    const url = localizeUrl(`/search/?keyword=${encodeURIComponent(keyword)}`);
    window.location.href = url;
  };
  const style = isHeader ? "" : "display: contents;";
</script>

<form
  class={isHeader ? "header_search" : "support_search"}
  on:submit|preventDefault={handleSubmit}
>
  <p class="clearfix" {style}>
    <input
      type="text"
      id="search"
      name="search"
      bind:value={keyword}
      class="item_txt"
      placeholder={t("header.search.placeholder")}
    />
    <button
      type="submit"
      class="item_btn js_search_submit"
      aria-label={t("form.submit")}
    />
  </p>
  {#if showActionButtons && !isEnglish}
    <p class="headernav_btnwrap">
      <a href="/member-menu/" class="member_menu_btn">
        特別会員
        <br />
        専用メニュー
      </a>
      <a href="/facilities/" class="office_reserve_btn"> 施設ご予約 </a>
    </p>
  {/if}
</form>
